@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
  --code-bg: #282c34;
  --code-text: #d4d4d4;
  --code-line-number: #858585;
  --code-highlight-bg: #264f78;
  --code-header-bg: #323233;
}

body {
  font-family: 'Arial', sans-serif;
}

.container {
  max-width: 1200px;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

/* Add padding to the top of the content to prevent overlap with fixed navbar */
.content {
  padding-top: 64px; /* Adjust this value based on your navbar height */
}

@keyframes slideInFromRight {
  from {
    transform: translateX(25%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-right {
  animation: slideInFromRight 1s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in-05s {
  animation: fadeIn 0.5s ease-in-out;
}

.fade-in-1s {
  animation: fadeIn 1s ease-in-out;
}

.fade-in-2s {
  animation: fadeIn 3s ease-in-out;
}

.fade-in-3s {
  animation: fadeIn 3s ease-in-out;
}

.hover-link {
  color: #81E6D9;
}

.hover-link:hover {
  color: #4FD1C5;
}

/* Markdown styles */
.markdown h1 {
  @apply text-teal-200 text-4xl mb-4;
}

.markdown h2 {
  @apply text-teal-200 text-3xl mb-4;
}

.markdown h3 {
  @apply text-teal-200 text-xl mb-4;
}

.markdown p {
  @apply text-gray-300 mb-4;
}

.markdown a {
  @apply text-teal-400 underline;
}

.markdown a:hover {
  @apply text-teal-200;
}

.markdown ul {
  @apply list-disc ml-6;
}

.markdown ol {
  @apply list-decimal ml-6;
}

.markdown blockquote {
  @apply border-l-4 border-teal-400 pl-4 text-gray-400 mb-4;
}

/* Updated Code block styling */
.markdown pre {
  @apply rounded-lg overflow-hidden mb-4 relative;
  background-color: var(--code-bg);
  color: var(--code-text);
  font-family: 'Consolas', 'Monaco', 'Andale Mono', 'Ubuntu Mono', monospace;
  font-size: 14px;
  line-height: 1.5;
}

.markdown pre .code-header {
  @apply flex justify-between items-center px-4 py-2 text-sm;
  background-color: #2d3748; /* Dark background color */
}

.markdown pre .code-header .filename {
  @apply text-teal-200; /* Teal color for text */
}

.markdown pre .code-header .copy-button {
  @apply bg-teal-500 hover:bg-teal-400 text-gray-900 px-2 py-1 rounded text-xs cursor-pointer;
}

.markdown pre code {
  @apply block p-4;
  counter-reset: line;
  background-color: var(--code-bg);
  color: var(--code-text);
}

.markdown pre code .line {
  display: inline-block;
  width: 100%;
  padding-left: 3.5em;
  position: relative;
}

.markdown pre code .line:before {
  counter-increment: line;
  content: counter(line);
  position: absolute;
  left: 0;
  width: 2.5em;
  text-align: right;
  color: var(--code-line-number);
  padding-right: 1em;
  border-right: 1px solid var(--code-line-number);
  user-select: none;
}

.markdown pre code .highlight {
  background-color: var(--code-highlight-bg);
  display: block;
  margin-left: -3.5em;
  margin-right: -1rem;
  padding-left: 3.5em;
  padding-right: 1rem;
}

.markdown code {
  @apply bg-gray-800 text-gray-300 p-1 rounded;
}

/* Table of Contents styles */
.table-of-contents {
  @apply bg-gray-700 p-4 rounded-lg mb-6;
}

.table-of-contents h2 {
  @apply text-xl font-bold mb-2 text-teal-200;
}

.table-of-contents ul {
  @apply list-none p-0;
}

.table-of-contents li {
  @apply mb-1;
}

.table-of-contents a {
  @apply text-teal-300 hover:text-teal-200 no-underline;
}

.table-of-contents a:hover {
  @apply text-teal-200;
}

/* Custom scrollbar styles */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #2d3748;
}

::-webkit-scrollbar-thumb {
  background-color: #4fd1c5;
  border-radius: 10px;
  border: 3px solid #2d3748;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a3f3ef;
}